import React from "react";
import Layout from '../components/layout'
import {
  Button,
} from "gatsby-theme-material-ui";
import {Alert, Grid} from "@mui/material";
const ThankYouPage = () => {
  return (
    <Layout pageTitle="Thank You">
      <Grid container spacing={0}>
        <Grid item xs={1}/>
        <Grid item xs={10}>
      <ul>
        <li>        <Alert severity="success" sx={{ width: '100%' }}>
          We have received your information and will be in touch soon!
        </Alert>
        </li>
        <li/>
        <li>Thank you for your interest. We will be in touch soon.</li>
        <li>In the meantime. Feel free to get a head start with our <Button to="/videos"  sx={{ my: 1, mx: 1.5 }}>Video Archive</Button></li>
      </ul>
        </Grid>
      </Grid>
    </Layout>
  )
};


export default ThankYouPage;
